import React, { FunctionComponent, useRef, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "../Link";
import "./styles.scss";
import { PortableText, ImageInterface, MultiLineHeadline, SanityColorList } from "../../types/SanityTypes";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export interface SectionTeaserInterface {
  textAlignment: string;
  sectionBlockType: {
    name: string;
  };
  ctaReference: {
    _id: string;
    teaser: {
      teaserCTALabel: string;
      _rawTeaserText: PortableText;
      _rawTeaserSecondaryImage: ImageInterface;
      _rawTeaserHeroImage: ImageInterface;
      sectionTeaserHeadline: MultiLineHeadline;
      // For Preview
      teaserText?: PortableText;
      teaserSecondaryImage?: ImageInterface;
      teaserHeroImage?: ImageInterface;
      teaserHeadline?: MultiLineHeadline;
    };
    main: {
      sectionColor: SanityColorList;
      sectionNumber: string;
    };
    slug: {
      current: string;
    };
  };
}

const SectionTeaser: FunctionComponent<SectionTeaserInterface> = ({
  textAlignment,
  sectionBlockType,
  ctaReference
}) => {
  gsap.registerPlugin(ScrollTrigger);

  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const colorVariations = {
    Purple: {
      primaryGradientColor: `var(--purple-light-color)`,
      secondaryGradientColor: `var(--pink-light-color)`,
      gradientDegrees: 30
    },
    Red: {
      primaryGradientColor: "var(--orange-light-color)",
      secondaryGradientColor: "var(--pink-bright-color)",
      gradientDegrees: -150
    },
    Teal: {
      primaryGradientColor: "var(--get-mentored-green-color)",
      secondaryGradientColor: "var(--get-mentored-blue-color)",
      gradientDegrees: 225
    },
    Pink: {
      primaryGradientColor: "var(--pink-bright-color)",
      secondaryGradientColor: "var(--pink-bright-color)",
      gradientDegrees: -146
    },
    Blue: {
      primaryGradientColor: "var(--blue-dark-color)",
      secondaryGradientColor: "var(--blue-bright-color)",
      gradientDegrees: "90"
    },
    Primary: {
      primaryGradientColor: "var(--primary-color)",
      secondaryGradientColor: "var(--primary-color)",
      gradientDegrees: "90"
    }
  };
  const colorVariation: string = ctaReference.main?.sectionColor.title;
  const themeColors = colorVariations[colorVariation as keyof typeof colorVariations];
  const gradient = () => {
    return `linear-gradient(${themeColors?.gradientDegrees}deg, ${themeColors?.primaryGradientColor} 0%, ${themeColors?.secondaryGradientColor})`;
  };

  const heroImage = ctaReference.teaser?._rawTeaserHeroImage || ctaReference.teaser?.teaserHeroImage;
  const secondaryImage = ctaReference.teaser?._rawTeaserSecondaryImage || ctaReference.teaser?.teaserSecondaryImage;
  const text = ctaReference.teaser?._rawTeaserText || ctaReference.teaser?.teaserText;
  const teaserHeadline = ctaReference.teaser?.sectionTeaserHeadline || ctaReference.teaser?.teaserHeadline;

  const heading =
    (teaserHeadline?.primaryText || "") +
    " " +
    (teaserHeadline?.secondaryText || "") +
    " " +
    (teaserHeadline?.tertiaryText || "");

  const headingRef = heading.replace(/[^a-zA-Z0-9]/g, "-");
  const lineOne = useRef(null);
  const lineTwo = useRef(null);
  const lineThree = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      lineOne.current,
      {
        x: -300,
        ease: Power3.easeOut,
        delay: 0.25,
        duration: 1.3,
        scrollTrigger: { trigger: `#${headingRef}` }
      },
      {
        x: 0,
        ease: Power3.easeOut,
        delay: 0.25,
        duration: 1.3,
        scrollTrigger: { trigger: `#${headingRef}` }
      }
    );
    gsap.fromTo(
      lineTwo.current,
      {
        x: 300,
        ease: Power3.easeOut,
        delay: 0.5,
        duration: 1.5,
        scrollTrigger: { trigger: `#${headingRef}` }
      },
      {
        x: 0,
        ease: Power3.easeOut,
        delay: 0.5,
        duration: 1.5,
        scrollTrigger: { trigger: `#${headingRef}` }
      }
    );
    gsap.fromTo(
      lineThree.current,
      {
        x: -300,
        ease: Power3.easeOut,
        delay: 0.2,
        duration: 1.75,
        scrollTrigger: { trigger: `#${headingRef}` }
      },
      {
        x: 0,
        ease: Power3.easeOut,
        delay: 0.2,
        duration: 1.75,
        scrollTrigger: { trigger: `#${headingRef}` }
      }
    );
  }, [lineOne, lineTwo, lineThree, headingRef]);

  if (sectionBlockType.name === "Animated Headline") {
    return (
      <section
        className={"section-teaser " + (textAlignment === "Top" ? "type-1" : "type-2")}
        data-testid="section-teaser"
      >
        <Container fluid>
          <Row>
            <Col lg={{ offset: 1 }}>
              <span className="counter" aria-hidden="true" style={{ color: ctaReference.main.sectionColor.value }}>
                {ctaReference.main.sectionNumber}
              </span>

              {textAlignment === "Top" && (
                <div className="header">
                  <h2 className="heading-title" id={headingRef} aria-label={heading}>
                    <span className="line-1" ref={lineOne} aria-hidden="true">
                      {teaserHeadline?.primaryText}
                    </span>
                    {teaserHeadline?.secondaryText && (
                      <span
                        className="line-2"
                        ref={lineTwo}
                        style={{ color: ctaReference.main.sectionColor.value }}
                        aria-hidden="true"
                      >
                        {teaserHeadline?.secondaryText}
                      </span>
                    )}
                    {teaserHeadline?.tertiaryText && (
                      <span className="line-3" ref={lineThree} aria-hidden="true">
                        {teaserHeadline?.tertiaryText}
                      </span>
                    )}
                  </h2>
                </div>
              )}

              {textAlignment === "Centre" && (
                <div className="header">
                  <h2
                    className="heading-title"
                    id={headingRef}
                    style={{ color: ctaReference.main.sectionColor.value }}
                    aria-label={heading}
                  >
                    <span className="line-1" ref={lineOne} aria-hidden="true">
                      {teaserHeadline?.primaryText}{" "}
                    </span>
                    {teaserHeadline?.secondaryText && (
                      <span
                        className="line-2"
                        ref={lineTwo}
                        style={{ color: ctaReference.main.sectionColor.value }}
                        aria-hidden="true"
                      >
                        {teaserHeadline?.secondaryText}{" "}
                      </span>
                    )}
                    {teaserHeadline?.tertiaryText && (
                      <span className="line-3" ref={lineThree} aria-hidden="true">
                        {teaserHeadline?.tertiaryText}
                      </span>
                    )}
                  </h2>
                </div>
              )}

              <BlockContent className="body-copy" blocks={text} />

              {textAlignment === "Top" && (
                <div className="overlap-images">
                  {heroImage && (
                    <picture data-testid="product-image">
                      <source
                        media={"(min-width: 1200px)"}
                        srcSet={
                          urlBuilder
                            .image(heroImage)
                            .auto("format")
                            .quality(80)
                            .width(811)
                            .height(468)
                            .format("webp")
                            .url() || undefined
                        }
                      />
                      <source
                        media={"(min-width: 768px)"}
                        srcSet={
                          urlBuilder
                            .image(heroImage)
                            .auto("format")
                            .quality(80)
                            .width(711)
                            .height(404)
                            .format("webp")
                            .url() || undefined
                        }
                      />
                      <img
                        width="600px"
                        height="697px"
                        className="img-fluid primary-image"
                        src={
                          urlBuilder
                            .image(heroImage)
                            .auto("format")
                            .quality(80)
                            .width(600)
                            .height(697)
                            .format("webp")
                            .url() || undefined
                        }
                        loading={"lazy"}
                        alt={heroImage.alt as string}
                      />
                    </picture>
                  )}
                  <div className="bottom-gradient" style={{ background: gradient() }} />
                </div>
              )}

              {textAlignment === "Centre" && (
                <div className="overlap-images">
                  {heroImage && (
                    <picture data-testid="product-image">
                      <source
                        media={"(min-width: 768px)"}
                        srcSet={
                          urlBuilder
                            .image(heroImage)
                            .auto("format")
                            .quality(80)
                            .width(630)
                            .height(928)
                            .format("webp")
                            .url() || undefined
                        }
                      />
                      <img
                        className="img-fluid primary-image"
                        width="540"
                        height="600"
                        loading={"lazy"}
                        src={
                          urlBuilder
                            .image(heroImage)
                            .auto("format")
                            .quality(80)
                            .width(540)
                            .height(600)
                            .format("webp")
                            .url() || undefined
                        }
                        alt={heroImage.alt as string}
                      />
                    </picture>
                  )}
                </div>
              )}

              <Link _id={ctaReference._id} to={ctaReference.slug.current} className="btn-primary">
                {ctaReference.teaser?.teaserCTALabel}
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    );
  } else {
    return (
      <section className="section-teaser type-3" data-testid="section-teaser">
        <Container fluid>
          <Row>
            <Col>
              <span className="counter" aria-hidden="true" style={{ color: ctaReference.main?.sectionColor.value }}>
                {ctaReference.main?.sectionNumber}
              </span>

              <div className="ticker-1" aria-hidden="true">
                <div style={{ color: ctaReference.main?.sectionColor.value }}>
                  {teaserHeadline?.primaryText}
                  <span>{teaserHeadline?.secondaryText}</span>
                  {teaserHeadline?.tertiaryText} {teaserHeadline?.primaryText}
                  <span>{teaserHeadline?.secondaryText}</span>
                  {teaserHeadline?.tertiaryText} {teaserHeadline?.primaryText}
                  <span>{teaserHeadline?.secondaryText}</span>
                  {teaserHeadline?.tertiaryText}
                </div>
              </div>
              <h2 className="sr-only">
                {teaserHeadline?.primaryText} {teaserHeadline?.secondaryText} {teaserHeadline?.tertiaryText}
              </h2>

              <BlockContent className="body-copy" blocks={text} />

              <div className="overlap-images">
                {heroImage && secondaryImage && (
                  <div>
                    <picture data-testid="product-image">
                      <source
                        media={"(min-width: 1200px)"}
                        srcSet={
                          urlBuilder
                            .image(heroImage)
                            .auto("format")
                            .quality(80)
                            .width(498)
                            .height(874)
                            .format("webp")
                            .url() || undefined
                        }
                      />
                      <source
                        media={"(min-width: 768px)"}
                        srcSet={
                          urlBuilder
                            .image(heroImage)
                            .auto("format")
                            .quality(80)
                            .width(498)
                            .height(874)
                            .format("webp")
                            .url() || undefined
                        }
                      />
                      <img
                        width="568px"
                        height="697px"
                        className="img-fluid primary-image"
                        src={
                          urlBuilder
                            .image(heroImage)
                            .auto("format")
                            .quality(80)
                            .width(568)
                            .height(697)
                            .format("webp")
                            .url() || undefined
                        }
                        loading={"lazy"}
                        alt={heroImage.alt as string}
                      />
                    </picture>
                    <picture data-testid="product-image">
                      <source
                        media={"(min-width: 1200px)"}
                        srcSet={
                          urlBuilder
                            .image(secondaryImage)
                            .auto("format")
                            .quality(80)
                            .width(501)
                            .height(466)
                            .format("webp")
                            .url() || undefined
                        }
                      />
                      <source
                        media={"(min-width: 768px)"}
                        srcSet={
                          urlBuilder
                            .image(secondaryImage)
                            .auto("format")
                            .quality(80)
                            .width(501)
                            .height(466)
                            .format("webp")
                            .url() || undefined
                        }
                      />
                      <img
                        width="501px"
                        height="466px"
                        className="img-fluid secondary-image"
                        src={
                          urlBuilder
                            .image(secondaryImage)
                            .auto("format")
                            .quality(80)
                            .width(501)
                            .height(466)
                            .format("webp")
                            .url() || undefined
                        }
                        loading={"lazy"}
                        alt={secondaryImage.alt as string}
                      />
                    </picture>
                  </div>
                )}
                <div className="bottom-gradient" style={{ background: gradient() }} />
              </div>
              <Link _id={ctaReference._id} to={ctaReference.slug?.current} className="btn-primary">
                {ctaReference.teaser?.teaserCTALabel}
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
};

export default SectionTeaser;
